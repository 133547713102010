import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Styled from './style'

const Image = ({ image, title, id, index, onLoad }) => {
    const [loaded, setLoaded] = useState(false)
    const identifier = `item-${id}`

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            onLoad()
        }
    }, [loaded])

    return (
        <Styled.Image
            id={identifier}
            className="item-img-wrap js-img-wrap js-img aspect-ratio"
            data-index={index}
        >
            <GatsbyImage
                className="js-item-img item-img"
                image={getImage(image)}
                alt={title}
                loading="eager"
            />
        </Styled.Image>
    )
}

export default Image
