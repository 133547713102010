import React, { useState, useEffect, useCallback } from 'react'
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import Item from './item'
import Container from './container'
import Progress from './progress'
import ArrowLeft from '../images/arrow-left.inline.svg'
import ArrowRight from '../images/arrow-right.inline.svg'
import useWindowSize from '../hooks/useWindowSize'
import SliderItem from '../controllers/sliderItem'
import Styled from './style'

gsap.registerPlugin(CustomEase)

CustomEase.create('in-out-smooth', 'M0,0 C0.8,0 0.2,1 1,1')

function slide(slides, scroll) {
    const scrollLast = Math.abs(scroll.state.last)
    let progress = 0
    const slideContainer = document.getElementById('slider')

    slides.forEach((item, index) => {
        const { bounds, button } = item
        const inView =
            scrollLast + window.innerWidth >= bounds.left &&
            scrollLast < bounds.right

        if (inView) {
            const min = bounds.left - window.innerWidth
            const max = bounds.right
            const percentage = ((scrollLast - min) * 100) / (max - min)
            const newMin = -(window.innerWidth / 7)
            const newMax = 0
            item.x = ((percentage - 0) / (100 - 0)) * (newMax - newMin) + newMin
            item.img.style.transform = `translate3d(${item.x}px, 0, 0) scale(1.75)`
            gsap.to(button, {
                duration: 0.8,
                ease: 'expo.out',
                y: 0,
                delay: 0.15,
            })
        } else {
            gsap.to(button, {
                duration: 0.8,
                ease: 'expo.in',
                y: -slides[0].bounds.height,
            })
        }

        const min = 0
        const max = -scroll.state.bounds.width + window.innerWidth
        progress = ((scroll.state.last - min) * 100) / (max - min) / 100

        document.querySelector(
            '.js-progress'
        ).style.transform = `scaleX(${progress})`
    })
}

const animateIntro = (slides, scroll) => {
    function slideThis() {
        slide(slides, scroll)
    }
    gsap.ticker.add(slideThis)
}

const Slider = ({ nodes }) => {
    const [scroll, setScroll] = useState(null)
    const [slides, setSlides] = useState([])
    const { width: windowWidth } = useWindowSize()

    const loadScroll = (val) => {
        setScroll(val)
    }

    const loadSlides = (val) => {
        setSlides([...slides, val])
    }

    useEffect(() => {
        const arr = []
        ;[...document.querySelectorAll('.js-item')].forEach((el, index) => {
            const sliderItem = new SliderItem(el)
            arr[index] = sliderItem
        })
        setSlides(arr)
    }, [])

    useEffect(() => {
        if (scroll) {
            scroll.init()
            if (slides.length === nodes.length) {
                animateIntro(slides, scroll)
            }
        }
    }, [slides, scroll])

    const move = (e) => {
        const val = (e * windowWidth) / 2
        scroll.move(val)
    }

    return (
        <Styled.Slider id="slider">
            <Container updateScroll={(e) => loadScroll(e)}>
                {nodes.length &&
                    nodes.map((item, index) => (
                        <Item
                            {...item}
                            index={index}
                            key={index}
                            onLoad={(e) => loadSlides(e)}
                        />
                    ))}
            </Container>
            <Progress loaded={true} />
            <Styled.Nav className="js-sliderNav">
                <Styled.NavButton onClick={() => move(1)}>
                    <ArrowLeft />
                </Styled.NavButton>
                <Styled.NavButton onClick={() => move(-1)}>
                    <ArrowRight />
                </Styled.NavButton>
            </Styled.Nav>
        </Styled.Slider>
    )
}

export default Slider
