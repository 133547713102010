import React from 'react'
import Layout from '../components/layout'
import Slider from '../components/slider'
import { useStaticQuery, graphql } from 'gatsby'

const MarketingPage = () => {
    const data = useStaticQuery(graphql`
        {
            allClientsJson {
                nodes {
                    id
                    image {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    title
                    link
                    category
                }
            }
        }
    `)
    const { nodes } = data.allClientsJson

    return (
        <Layout
            title="Social Hustle's Complete Digital Marketing Portfolio"
            description="Explore the breadth and depth of our experience and success with this comprehensive collection of our past projects and clients."
        >
            <Slider nodes={nodes} />
        </Layout>
    )
}

export default MarketingPage
