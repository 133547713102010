import React, { useEffect, useState, useRef } from 'react'
import Button from './button'
import Image from './image'
import SliderItem from '../controllers/sliderItem'
import mediaQueries from '../constants/mediaQueries'
import Styled from './style'

const Item = ({ title, id, image, link, index, onLoad }) => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef(null)

    const imgProps = { index, title, id, image }

    /**useEffect(() => {
        if (loaded && ref.current) {
            const slide = new SliderItem(ref.current)
            onLoad(slide)
        }
    }, [loaded, ref])**/

    return (
        <Styled.Item ref={ref} className="item js-item aspect-ratio">
            <Image {...imgProps} onLoad={() => setLoaded(true)} />
            <Styled.Content className="item-content js-content">
                <Styled.HeadingWrap className="item-heading-wrap">
                    <Styled.Heading className="js-heading item-heading">
                        <span className="highlight-text">{title}</span>
                    </Styled.Heading>
                </Styled.HeadingWrap>
                <Button link={link}>Read More</Button>
            </Styled.Content>
        </Styled.Item>
    )
}

export default Item
